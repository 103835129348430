@use "../../global/" as g;

.l-main {
  margin-top: 75px;

  @include g.mq(md) {
    margin-top: 90px;
  }

  @include g.mq(xl) {
    margin-top: 110px;
  }
}

.l-main-sub {
  background: #f8faf9;
  position: relative;
  z-index: 0;
  &-bk01 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    svg {
      width: 104px;
      height: 284px;
      @include g.mq("md") {
        width: 138px;
        height: 388px;
      }
      @include g.mq("xl") {
        height: 490.1px;
        width: 171.29px;
      }
    }
  }
  &-bk02{
    position: absolute;
    left: -8%;
    top: 24%;
    z-index: -1;
    width: 113%;
    @include g.mq('sm'){
      top: 23%;
    }
    @include g.mq('md'){
      top: 40%;
      left: -5%;
    }
    @include g.mq('xl'){
      top: 16%;
    }
    svg {
      width: 100%;
    }
  }
}
