@charset "UTF-8";
/*--------------------------------------------------------------
* フォントの読み込み
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* 使用font の変数
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* フォントサイズ の変数
----------------------------------------------------------------*/
/* pc
_______________________________________________________________________________________________________*/
/* sp
_______________________________________________________________________________________________________*/
/* =========================================================================================================
	共通定義する値
========================================================================================================= */
/* コンテンツ幅
_______________________________________________________________________________________________________*/
/* 使用色
_______________________________________________________________________________________________________*/
/* イージング
_______________________________________________________________________________________________________*/
/*
 * Easing function
 * Original：http://easings.net/
 */
/*--------------------------------------------------------------
* globalフォルダの_index.scss
----------------------------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 0.875rem;
  font-display: swap;
  margin: 0 auto;
  padding: 0;
  background-color: #ffffff;
  color: inherit;
  color: #232536;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.1em;
  position: relative;
  overflow-x: hidden;
}
body figure {
  margin-bottom: 0;
}
body.is-nav-open {
  overflow: hidden;
}
@media screen and (min-width: 0) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 576px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1025px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  body {
    position: relative;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.8;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/*--------------------------------------------------------------
* foundationフォルダの_index.scss
----------------------------------------------------------------*/
.l-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}
.l-header__inner {
  background-color: #f6f9f8;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
}
@media screen and (min-width: 768px) {
  .l-header__inner {
    height: 90px;
    padding-left: 65px;
    padding-right: 65px;
  }
}
@media screen and (min-width: 1200px) {
  .l-header__inner {
    height: 110px;
    padding-left: 3.75vw;
    padding-right: 0;
  }
}
@media screen and (min-width: 1400px) {
  .l-header__inner {
    padding-left: 65px;
  }
}
.l-header__logo {
  max-width: 207px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .l-header__logo {
    max-width: 268px;
  }
}
.l-header__logo a {
  display: flex;
  align-items: center;
}
.l-header__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (min-width: 1200px) {
  .l-header__menu {
    align-items: stretch;
    height: 100%;
    width: 100%;
  }
}
.l-header__menu::before {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100vh;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 100%;
  visibility: hidden;
  width: 100%;
  transition: 0.4s ease 0s;
}
.l-header__menu.is-drop-open::before {
  opacity: 1;
  visibility: visible;
}
.l-header__menu-list {
  display: none;
}
@media screen and (min-width: 1200px) {
  .l-header__menu-list {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    font-size: 1rem;
    font-size: clamp(0.875rem, 1vw, 1rem);
    letter-spacing: 0.02em;
    margin-right: 45px;
    margin-right: clamp(20px, 2.8125vw, 45px);
  }
}
.l-header__menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.l-header__menu-item {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .l-header__menu-item {
    cursor: pointer;
  }
}
.l-header__menu-item > a:hover {
  color: #005bac;
  opacity: 1;
}
.l-header__menu-item + .l-header__menu-item {
  margin-left: clamp(20px, 2.5vw, 40px);
}
@media screen and (min-width: 1200px) {
  .l-header__menu-item + .l-header__menu-item {
    margin-left: clamp(20px, 1.4285714286vw, 20px);
  }
}
@media screen and (min-width: 1400px) {
  .l-header__menu-item + .l-header__menu-item {
    margin-left: 40px;
  }
}
.l-header__menu-item .c-icon-blank {
  position: relative;
  padding-right: 24px;
}
.l-header__menu-item .c-icon-blank::after {
  margin-left: 4px;
  position: absolute;
  top: calc(50% - 7.5px);
}
.l-header__menu-item .c-under-nav {
  position: relative;
  padding-right: 20px;
}
.l-header__menu-item .c-under-nav::before, .l-header__menu-item .c-under-nav::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 1px;
  background-color: #005bac;
  position: absolute;
  right: 0;
  top: calc(50% - 0.5px);
}
.l-header__menu-item .c-under-nav::after {
  transition: 0.4s ease-in-out;
  transform: rotate(90deg);
}
.l-header__menu-item.is-drop-open .c-under-nav::after {
  transform: rotate(0deg);
}
.l-header__menu-contact-box {
  display: none;
}
@media screen and (min-width: 1200px) {
  .l-header__menu-contact-box {
    white-space: nowrap;
    background-color: #005bac;
    height: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-size: 1.125rem;
    font-size: clamp(0.875rem, 1.125vw, 1.125rem);
    font-weight: 700;
    letter-spacing: 0.02em;
    position: relative;
    padding-right: clamp(20px, 2.75vw, 44px);
    padding-left: clamp(20px, 2.75vw, 44px);
  }
  .l-header__menu-contact-box::after {
    content: "";
    top: calc(50% - 10.5px);
    background: url("../../images/common/icon_contact.svg") no-repeat center;
    background-size: cover;
    width: 24px;
    height: 21px;
    margin-left: 12px;
    margin-top: 4px;
  }
}
.l-header__hambergur {
  display: block !important;
}
@media screen and (min-width: 1200px) {
  .l-header__hambergur {
    display: none !important;
  }
}
.l-header__menu-drop-down {
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 0;
}
.l-header__menu-drop-down-inner {
  max-width: 1240px;
  padding: 56px 0;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
  display: flex;
}
.l-header__menu-drop-down-header {
  width: calc(25.8620689655% - 1px);
  border-right: 1px solid #d1d4d7;
}
.l-header__menu-drop-down-title {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.l-header__menu-drop-down-main {
  width: 74.1379310345%;
  padding-left: 40px;
}
.l-header__menu-drop-down-lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 2.7906976744%;
}
.l-header__menu-drop-down-item {
  position: relative;
}
.l-header__menu-drop-down-item .c-circle-arrow01 {
  left: 0;
  right: auto;
  top: calc(50% - 13.5px);
}
.l-header__menu-drop-down-item .c-circle-arrow01__arrow {
  background-color: #ffffff;
  border: 1px solid #005bac;
  width: 27px;
  height: 27px;
  transition: 0.3s ease-in-out;
}
.l-header__menu-drop-down-item .c-circle-arrow01__arrow:after {
  border-bottom: 1px solid #005bac;
  border-right: 1px solid #005bac;
  top: calc(50% - 2.5px);
  left: calc(50% - 8.5px);
  width: 12px;
  height: 5px;
  transition: 0.3s ease-in-out;
}
.l-header__menu-drop-down-item:hover a {
  opacity: 1;
}
.l-header__menu-drop-down-item:hover .c-circle-arrow01__arrow {
  background-color: #005bac;
  border: 1px solid #005bac;
}
.l-header__menu-drop-down-item:hover .c-circle-arrow01__arrow::after {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.l-header__menu-drop-down-item a {
  display: flex;
}
.l-header__menu-drop-down-item-box {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  position: relative;
  margin-left: 40px;
  white-space: nowrap;
}
.l-header__sp-nav {
  visibility: hidden;
  transition: 0.4s ease-in-out;
  height: 0;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  top: 75px;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
}
.is-nav-open .l-header__sp-nav {
  opacity: 1;
  visibility: visible;
  height: calc(100% - 75px);
}
.l-header__sp-nav-content {
  height: auto;
}
.l-header__sp-nav-menu {
  padding: 20px 20px;
}
.l-header__sp-nav-lists {
  border-bottom: 1px solid #d1d4d7;
}
.l-header__sp-nav-item {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.02;
}
.l-header__sp-nav-item .l-header__sp-nav-item-box {
  display: block;
  padding-left: 10px;
  padding-bottom: 10px;
}
.l-header__sp-nav-item .c-icon-blank {
  position: relative;
}
.l-header__sp-nav-item .c-icon-blank::after {
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
}
.l-header__sp-nav-item + .l-header__sp-nav-item {
  border-top: 1px solid #d1d4d7;
}
.l-header__sp-nav-item + .l-header__sp-nav-item .l-header__sp-nav-item-box {
  padding-top: 10px;
}
.l-header__sp-nav-item .c-under-nav {
  position: relative;
  padding-right: 20px;
  display: block;
  height: 100%;
}
.l-header__sp-nav-item .c-under-nav::before, .l-header__sp-nav-item .c-under-nav::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 1px;
  background-color: #005bac;
  position: absolute;
  right: 10px;
  top: calc(50% - 0.5px);
}
.l-header__sp-nav-item .c-under-nav::after {
  transition: 0.4s ease-in-out;
  transform: rotate(90deg);
}
.l-header__sp-nav-item.is-drop-open .l-header__sp-nav-item-box {
  background-color: #f8faf9;
}
.l-header__sp-nav-item.is-drop-open .c-under-nav::after {
  transform: rotate(0deg);
}
.l-header__sp-nav-dropdown {
  display: none;
}
.l-header__sp-nav-dropdown-inner {
  padding: 12px 20px 20px;
}
.l-header__sp-nav-dropdown-item {
  font-size: 0.875rem;
  font-weight: 500;
}
.l-header__sp-nav-dropdown-item + .l-header__sp-nav-dropdown-item {
  margin-top: 16px;
}
.l-header__sp-contact {
  margin-top: 20px;
  padding-bottom: 40px;
}
.l-header__sp-contact-box {
  white-space: nowrap;
  background-color: #005bac;
  height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-size: 1.125rem;
  font-size: clamp(0.875rem, 1.125vw, 1.125rem);
  font-weight: 700;
  letter-spacing: 0.02em;
  position: relative;
  padding: 20px 10px;
}
.l-header__sp-contact-box::after {
  content: "";
  background: url("../../images/common/icon_contact.svg") no-repeat center;
  background-size: cover;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 10px;
  top: calc(50% - 10.5px);
}

.l-main {
  margin-top: 75px;
}
@media screen and (min-width: 768px) {
  .l-main {
    margin-top: 90px;
  }
}
@media screen and (min-width: 1200px) {
  .l-main {
    margin-top: 110px;
  }
}

.l-main-sub {
  background: #f8faf9;
  position: relative;
  z-index: 0;
}
.l-main-sub-bk01 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.l-main-sub-bk01 svg {
  width: 104px;
  height: 284px;
}
@media screen and (min-width: 768px) {
  .l-main-sub-bk01 svg {
    width: 138px;
    height: 388px;
  }
}
@media screen and (min-width: 1200px) {
  .l-main-sub-bk01 svg {
    height: 490.1px;
    width: 171.29px;
  }
}
.l-main-sub-bk02 {
  position: absolute;
  left: -8%;
  top: 24%;
  z-index: -1;
  width: 113%;
}
@media screen and (min-width: 576px) {
  .l-main-sub-bk02 {
    top: 23%;
  }
}
@media screen and (min-width: 768px) {
  .l-main-sub-bk02 {
    top: 40%;
    left: -5%;
  }
}
@media screen and (min-width: 1200px) {
  .l-main-sub-bk02 {
    top: 16%;
  }
}
.l-main-sub-bk02 svg {
  width: 100%;
}

.l-contact {
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-color: #005bac;
  padding-top: 50px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .l-contact {
    padding-top: 130px;
    padding-bottom: 113px;
  }
}
.l-contact__inner {
  position: relative;
  z-index: 3;
}
.l-contact__bk-content01 {
  position: absolute;
  z-index: 1;
  bottom: 2.5vw;
  right: -22vw;
  transform: rotate(-17deg);
}
.l-contact__bk-content01 svg {
  aspect-ratio: 735/318;
  width: clamp(400px, 45.9375vw, 735px);
}
.l-contact__bk-content02 {
  position: absolute;
  z-index: 1;
  bottom: 1.5vw;
  right: -19vw;
  transform: rotate(-20deg);
}
.l-contact__bk-content02 svg {
  aspect-ratio: 649/453;
  width: clamp(400px, 40.5625vw, 649px);
}

.l-footer {
  letter-spacing: 0.02em;
}
.l-footer__top {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #1d2b3b;
}
@media screen and (min-width: 768px) {
  .l-footer__top {
    padding-top: 112px;
    padding-bottom: 112px;
  }
}
@media screen and (min-width: 1025px) {
  .l-footer__top-top {
    display: flex;
  }
}
@media screen and (min-width: 1025px) {
  .l-footer__top-left {
    width: 27.5862068966%;
    margin-right: 100px;
  }
}
.l-footer__logo {
  max-width: 320px;
  width: 100%;
}
.l-footer__company-name {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
}
.l-footer__company-name-ja {
  color: #ffffff;
  font-size: 1.25rem;
}
.l-footer__company-name-en {
  text-transform: uppercase;
  color: #f8faf9;
  font-weight: 500;
  margin-top: -3px;
}
.l-footer__company-address {
  display: flex;
  flex-direction: column;
  color: #afb3b7;
  margin-top: 6px;
}
.l-footer__top-site-map {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
}
@media screen and (min-width: 1025px) {
  .l-footer__top-site-map {
    gap: 65px;
  }
}
@media screen and (min-width: 1200px) {
  .l-footer__top-site-map {
    gap: 65px;
  }
}
.l-footer__top-site-map-center, .l-footer__top-site-map-right {
  margin-top: 6px;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map-center, .l-footer__top-site-map-right {
    margin-top: 0;
  }
}
.l-footer__top-site-map-item {
  color: #ffffff;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map-item {
    font-size: 1rem;
  }
}
.l-footer__top-site-map-item.u-bk-service {
  background: url("../../images/common/img_footer-service-bk.webp") no-repeat center;
  background-size: cover;
  padding: 10px 0 10px 12px;
  font-size: 0.875rem;
  font-weight: 700;
}
.l-footer__top-site-map-item.u-bk-service .c-circle-arrow01__arrow {
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map-item + .l-footer__top-site-map-item.u-bk-service {
    margin-top: 30px !important;
  }
}
.l-footer__top-site-map-item + .l-footer__top-site-map-item {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map-item + .l-footer__top-site-map-item {
    margin-top: 20px;
  }
}
.l-footer__top-site-map-item a {
  display: flex;
  align-items: center;
}
.l-footer__top-site-map-item .c-circle-arrow01 {
  position: static;
}
.l-footer__top-site-map-item .c-circle-arrow01::after {
  position: static;
}
.l-footer__top-site-map-item .c-circle-arrow01__arrow {
  background-color: transparent;
}
.l-footer__top-site-map-item .c-circle-arrow01__arrow::after {
  top: calc(50% + 2.5px);
}
.l-footer__top-site-map-under-lists {
  color: #afb3b7;
  margin-top: 4px;
  letter-spacing: 0.02em;
}
.l-footer__top-site-map-under02-lists {
  color: #afb3b7;
  margin-top: 4px;
  letter-spacing: 0.02em;
  padding-left: 15px;
}
.l-footer__top-site-map-under-item {
  position: relative;
}
.l-footer__top-site-map-under-item a::before {
  content: "－";
  display: inline-block;
}
.l-footer__top-site-map-under-item + .l-footer__top-site-map-under-item {
  margin-top: 8px;
}
@media screen and (min-width: 768px) {
  .l-footer__top-site-map-under-item + .l-footer__top-site-map-under-item {
    margin-top: 0;
  }
}
.l-footer__banner {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-footer__banner {
    margin-top: 160px;
    align-items: center;
    flex-direction: row;
    gap: 40px;
  }
}
@media screen and (min-width: 768px) {
  .l-footer__banner-item {
    max-width: 345px;
    width: 100%;
  }
}
.l-footer__bottom {
  background-color: #f8faf9;
  padding: 14px 0;
}
.l-footer__bottom-inner {
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .l-footer__bottom-inner {
    display: flex;
    justify-content: space-between;
  }
}

.l-under-top {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.l-under-top__main {
  background-color: #ffffff;
  padding: 60px 0 60px;
}
@media screen and (min-width: 768px) {
  .l-under-top__main {
    padding: 145px 0 150px;
  }
}
.l-under-top__bk-content01 {
  position: absolute;
  right: -30vw;
  bottom: -8vw;
}
@media screen and (min-width: 576px) {
  .l-under-top__bk-content01 {
    right: -20vw;
    bottom: -8vw;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top__bk-content01 {
    right: -6vw;
    bottom: -9vw;
  }
}
.l-under-top__bk-content01 svg {
  aspect-ratio: 795.216/450.264;
  width: clamp(400px, 68.5344827586vw, 798px);
}
.l-under-top__bk-content02 {
  position: absolute;
  right: -23vw;
  bottom: 3vw;
}
@media screen and (min-width: 576px) {
  .l-under-top__bk-content02 {
    right: -15vw;
    bottom: 0vw;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top__bk-content02 {
    right: -6vw;
    bottom: -1vw;
  }
}
.l-under-top__bk-content02 svg {
  aspect-ratio: 867.587/300.969;
  width: clamp(400px, 68.7931034483vw, 798px);
}
.l-under-top .ball {
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.l-under-top01 .l-breadcrumbs, .l-under-top01 .l-breadcrumbs__lists {
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .l-under-top01 .l-breadcrumbs__lists {
    padding-bottom: 10px;
  }
}
.l-under-top01__main {
  position: relative;
  background: #F8FAF9;
  overflow: hidden;
  z-index: 0;
  padding: 80px 0;
  border-top: solid 1px #2995E5;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main {
    padding: 121px 0 129px;
  }
}
.l-under-top01__main01 {
  padding: 40px 0 65px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 {
    padding: 35px 0 57px;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01.style01 {
    padding: 35px 0 64px;
  }
}
.l-under-top01__main01 .p-under-top01__img {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 .p-under-top01__img {
    margin-bottom: 0;
  }
}
.l-under-top01__main01 .p-under-top01__desc {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 .p-under-top01__desc {
    margin-bottom: 32px;
  }
}
.l-under-top01__main01 .p-under-top01__desc:last-child {
  margin-bottom: 0;
}
.l-under-top01__bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 122px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__bk {
    width: 120px;
    height: 245px;
  }
}
@media screen and (min-width: 1200px) {
  .l-under-top01__bk {
    width: 171px;
    height: 317.41px;
  }
}
.l-under-top01__bk svg {
  width: 100%;
  height: 100%;
}

.l-breadcrumbs {
  position: relative;
  background-color: #f8faf9;
}
.l-breadcrumbs__lists {
  display: flex;
  overflow-x: auto;
  padding-top: 17px;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .l-breadcrumbs__lists {
    justify-content: flex-end;
  }
}
.l-breadcrumbs__item {
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #8e8e8e;
  white-space: nowrap;
}
.l-breadcrumbs__item.is-show {
  color: #232536;
}
.l-breadcrumbs__item + .l-breadcrumbs__item {
  margin-left: 10px;
  padding-left: 15px;
  position: relative;
}
.l-breadcrumbs__item + .l-breadcrumbs__item::before {
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
  content: "";
  display: inline-block;
  background: url("../../images/common/icon_bread-arrow.svg") no-repeat center;
  background-size: cover;
  width: 5px;
  height: 10px;
}

.l-scorporate {
  position: relative;
  overflow: hidden;
  padding: 60px 0 80px;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .l-scorporate {
    padding: 84px 0;
  }
}
.l-scorporate::before {
  position: absolute;
  content: "";
  left: -100vw;
  top: 0;
  width: 300vw;
  height: 100%;
  background: #fff;
  z-index: -1;
}
.l-scorporate .p-scorporate__title {
  font-size: 1.375rem;
  font-weight: 500;
  color: #232536;
  line-height: 1.5em;
  margin-bottom: 30px;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .l-scorporate .p-scorporate__title {
    margin-bottom: 39px;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .l-scorporate01 {
    padding: 53px 0 81px;
  }
}

.l-sframe-btns {
  background: #005BAC;
  padding: 35px 0;
}
@media screen and (min-width: 768px) {
  .l-sframe-btns {
    padding: 40px 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .l-sframe-btns.has-2block {
    padding: 41px 0 29px;
  }
}
@media screen and (min-width: 768px) {
  .l-sframe-btns.has-1btn {
    padding: 40px 0;
  }
}
.l-sframe-btns.has-1btn .c-sframe-btns {
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .l-sframe-btns.has-1btn .c-sframe-btns {
    justify-content: inherit;
  }
}

.l-sframe-btn {
  padding-bottom: 38px;
  border-bottom: solid 1px #fff;
  margin-bottom: 19px;
}
@media screen and (min-width: 768px) {
  .l-sframe-btn {
    padding-bottom: 30px;
    border-bottom: solid 2px #fff;
    margin-bottom: 38px;
  }
}
.l-sframe-btn:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.l-logistics-system-specification {
  padding: 40px 0 30px;
}
@media screen and (min-width: 768px) {
  .l-logistics-system-specification {
    padding: 98px 0 30px;
  }
}

.l-logistics-system-diagram {
  padding: 30px 0;
}
@media screen and (min-width: 768px) {
  .l-logistics-system-diagram {
    padding: 45px 0 0;
  }
}

.l-logistics-system-station {
  padding: 30px 0 60px;
}
@media screen and (min-width: 768px) {
  .l-logistics-system-station {
    padding: 77px 0 160px;
  }
}

/*--------------------------------------------------------------
* layoutフォルダの_index.scss
----------------------------------------------------------------*/
.p-under-top__title-en {
  color: #005bac;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .p-under-top__title-en {
    font-size: 4rem;
    font-size: clamp(2.125rem, 5.5172413793vw, 4rem);
  }
}
.p-under-top__title-ja {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 8px;
  font-weight: 500 !important;
}
@media screen and (min-width: 768px) {
  .p-under-top__title-ja {
    font-size: 2.5rem;
    font-size: clamp(1.5rem, 3.4482758621vw, 2.5rem);
    margin-top: 17px;
  }
}

.p-under-top01__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .p-under-top01__main {
    flex-wrap: nowrap;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__main.no-title {
    align-items: flex-start;
  }
}
.p-under-top01__img {
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-under-top01__img {
    width: 48.276%;
    margin: 0;
    top: 4px;
  }
}
.p-under-top01__info {
  width: 100%;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-under-top01__info {
    width: 48.276%;
  }
}
.p-under-top01__info.has-margin {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__info.has-margin {
    margin-bottom: 0;
  }
}
.p-under-top01__title {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title {
    margin-bottom: 31px;
  }
}
.p-under-top01__title .en {
  position: relative;
  font-family: "Josefin Slab", serif;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #005BAC;
  display: block;
  line-height: 1.7em;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title .en {
    top: 5px;
    font-size: 1.5rem;
  }
}
.p-under-top01__title .jp {
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #232536;
  display: block;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title .jp {
    font-size: 2.5rem;
    font-size: clamp(2rem, 3.4482758621vw, 2.5rem);
  }
}
.p-under-top01__desc {
  color: #232536;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 2em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__desc {
    font-size: 1rem;
  }
}
.p-under-top01__title01 {
  font-size: 1.625rem;
  font-weight: 500;
  color: #005BAC;
  letter-spacing: 0.02em;
  line-height: 1.7em;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01 {
    font-size: 2.5rem;
    margin-bottom: 17px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.mb40 {
    margin-bottom: 41px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.mb20 {
    margin-bottom: 20px;
  }
}
.p-under-top01__title01.has-logotxt {
  padding-left: 70px;
  margin-bottom: 8px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.has-logotxt {
    padding-left: 110px;
    margin-bottom: 18px;
  }
}
.p-under-top01__title01.has-logotxt::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: url(../../images/common/img_utop_main_logo_01.webp) no-repeat center/contain;
  width: 65px;
  height: 44px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__title01.has-logotxt::before {
    width: 85px;
    height: 64px;
    top: 3px;
  }
}
.p-under-top01__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__list {
    margin-bottom: 26px;
  }
}
.p-under-top01__list.txt-2line .p-under-top01__icon {
  padding: 3px 2px;
  height: 60%;
}
.p-under-top01__list.txt-2line .p-under-top01__txt {
  padding: 10px 2px;
  height: 40%;
}
@media screen and (min-width: 768px) {
  .p-under-top01__list.txt-2line .p-under-top01__txt {
    padding: 3px 2px 2px;
  }
}
.p-under-top01__item {
  margin: 0 6px 10px 0;
  width: 50px;
  border: solid 1px #005BAC;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item {
    margin: 0 8px 10px 0;
    width: 62px;
  }
}
.p-under-top01__item:last-child {
  margin: 0 0 10px 0;
}
.p-under-top01__icon, .p-under-top01__txt {
  width: 100%;
  text-align: center;
}
.p-under-top01__icon {
  height: 64%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.p-under-top01__icon .icon {
  line-height: 0;
  display: block;
  margin: auto;
}
.p-under-top01__txt {
  height: 36%;
  background: #005BAC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px;
}
.p-under-top01__txt .txt {
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt {
    font-size: 0.875rem;
  }
}
.p-under-top01__txt .txt-small01 {
  font-size: 0.6875rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt-small01 {
    font-size: 0.75rem;
  }
}
.p-under-top01__txt .txt-small02 {
  position: relative;
  display: block;
  font-size: 0.625rem;
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  .p-under-top01__txt .txt-small02 {
    top: -2px;
  }
}
.p-under-top01__item.icon-01 .icon {
  width: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-01 .icon {
    width: 24px;
  }
}
.p-under-top01__item.icon-02 .icon {
  width: 27px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-02 .icon {
    width: 31px;
  }
}
.p-under-top01__item.icon-03 .icon {
  width: 40px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-03 .icon {
    width: 45px;
  }
}
.p-under-top01__item.icon-04 .icon {
  width: 24px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-04 .icon {
    width: 28px;
  }
}
.p-under-top01__item.icon-05 .icon {
  width: 26px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-05 .icon {
    width: 31px;
  }
}
.p-under-top01__item.icon-06 .icon {
  width: 34px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-06 .icon {
    width: 38px;
  }
}
.p-under-top01__item.icon-07 .icon {
  width: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-07 .icon {
    width: 24px;
  }
}
.p-under-top01__item.icon-08 .icon {
  width: 16px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__item.icon-08 .icon {
    width: 20px;
  }
}
.p-under-top01__label {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-top01__label {
    margin-bottom: 36px;
  }
}
.p-under-top01__label .txt {
  border: solid 1px #005BAC;
  color: #005BAC;
  display: inline-block;
  padding: 5px 12px 4px;
  line-height: 1.2em;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-under-top01__label .txt {
    padding: 4px 9px 5px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .p-contact-content {
    display: flex;
    justify-content: space-between;
  }
}
.p-contact-content__left {
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .p-contact-content__left {
    width: 46.5517241379%;
  }
}
.p-contact-content__text {
  margin-top: 20px;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-contact-content__text {
    margin-top: 29px;
    font-size: 1rem;
  }
}
.p-contact-content__right {
  flex: 1;
}
.p-contact-content__link-area {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .p-contact-content__link-area {
    margin-top: 60px;
  }
}
@media screen and (min-width: 768px) {
  .p-contact-content__link-area .c-link-btn01 {
    margin-right: 50px;
  }
}

.p-under-box {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  margin-bottom: 60px;
  pointer-events: none;
}
.p-under-box:hover .p-under-box__img a {
  opacity: 1;
}
.p-under-box:hover .p-under-box__img img {
  transform: scale(1.1);
}
.p-under-box:hover .c-circle-arrow01__arrow {
  transform: translateX(5px);
}
@media screen and (min-width: 768px) {
  .p-under-box {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 120px;
  }
}
.p-under-box.flex-start {
  align-items: flex-start;
}
.p-under-box:last-child {
  margin-bottom: 0 !important;
}
.p-under-box .c-section-title {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box .c-section-title {
    margin-bottom: 28px;
    margin-bottom: clamp(10px, 2.4137931034vw, 28px);
  }
}
.p-under-box .c-section-title .c-section-title__en {
  color: #005bac;
}
@media screen and (min-width: 768px) {
  .p-under-box .c-section-title .c-section-title__en {
    margin-bottom: -4px;
  }
}
.p-under-box__img, .p-under-box__main {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-under-box__img, .p-under-box__main {
    width: 48.277%;
  }
}
.p-under-box__img {
  overflow: hidden;
}
.p-under-box__img a {
  display: block;
  pointer-events: auto;
}
.p-under-box__img img {
  transition: 0.4s ease-in-out;
}
.p-under-box__main {
  position: relative;
}
.p-under-box__main .c-sbtn__link {
  pointer-events: auto;
}
.p-under-box__img {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box__img {
    margin-bottom: 0;
  }
}
.p-under-box__img .img {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-under-box__img .img {
    margin-bottom: 40px;
  }
}
.p-under-box__img .img.last {
  margin-bottom: 0;
}
.p-under-box .desc {
  color: #232536;
  line-height: 2em;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-under-box .desc {
    font-size: 1rem;
  }
}
.p-under-box__desc {
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #232536;
  margin-bottom: 20px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .p-under-box__desc {
    margin-bottom: 55px;
    margin-bottom: clamp(1px, 4.7413793103vw, 55px);
    font-size: 1.125rem;
    margin-top: 24px;
  }
}
.p-under-box__reverse {
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-under-box__reverse {
    flex-direction: row;
  }
}
.p-under-box__reverse .p-under-box__desc {
  padding-right: 0;
}
.p-under-box__ttl {
  font-size: 1.5rem;
  font-weight: 500;
  color: #005bac;
  letter-spacing: 0.02em;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-under-box__ttl {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box__ttl {
    font-size: 2rem;
  }
}
.p-under-box.no-margin .p-under-box__main, .p-under-box.no-margin .p-under-box__desc {
  margin: 0;
}
.p-under-box.no-padding .p-under-box__main, .p-under-box.no-padding .p-under-box__desc {
  padding: 0;
}
.p-under-box__panel {
  margin-bottom: 15px;
}
.p-under-box__panel:last-child {
  margin-bottom: 0;
}
.p-under-box__panel .ttl, .p-under-box__panel .desc {
  font-size: 0.875rem;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-under-box__panel .ttl, .p-under-box__panel .desc {
    font-size: 1rem;
  }
}
.p-under-box__panel .ttl {
  font-weight: bold;
  color: #005BAC;
  line-height: 1.7em;
}
.p-under-box__panel .desc {
  color: #232536;
  line-height: 2em;
}
.p-under-box__panel .c-section-stitle02 {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-box__panel .c-section-stitle02 {
    margin-bottom: 12px;
  }
}
.p-under-box__frame {
  padding: 15px;
  background: #F8FAF9;
}
@media screen and (min-width: 768px) {
  .p-under-box__frame {
    padding: 25px;
  }
}
.p-under-box__frame .desc {
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  color: #232536;
}
@media screen and (min-width: 768px) {
  .p-under-box__frame .desc {
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-under-box__label {
  display: inline-block;
  border: solid 1px #005BAC;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 2px 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__label {
    padding: 0 9px;
    margin-bottom: 12px;
  }
}
.p-under-box__label .txt {
  display: inline-block;
  line-height: 1.2em;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #005BAC;
}
@media screen and (min-width: 768px) {
  .p-under-box__label .txt {
    font-size: 1rem;
  }
}
.p-under-box__video {
  width: 100%;
  position: relative;
}
.p-under-box__video a {
  position: relative;
  display: block;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .p-under-box__video a img {
    transition: all 0.5s;
  }
  .p-under-box__video a:hover img {
    opacity: 0.7;
    transform: none;
  }
  .p-under-box__video a:hover::before {
    background: url(../../images/common/icon_youtube_01.svg) center center/contain no-repeat;
  }
}
.p-under-box__video a::before {
  content: "";
  display: block;
  width: 58px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  background: url(../../images/common/icon_youtube_02.svg) center center/contain no-repeat;
}
@media screen and (min-width: 768px) {
  .p-under-box__video a::before {
    width: 64px;
    height: 45px;
  }
}
.p-under-box__section {
  padding-top: 40px;
  margin-bottom: 42px;
  border-top: solid 1px #D1D4D7;
}
@media screen and (min-width: 768px) {
  .p-under-box__section {
    padding-top: 79px;
    margin-bottom: 84px;
  }
}
.p-under-box__section.no-padding {
  padding: 0;
}
.p-under-box__section.no-border {
  border: 0;
}
.p-under-box__section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.p-under-box.has-label .p-under-box__ttl {
  position: relative;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__ttl {
    top: -2px;
    margin-bottom: 13px;
  }
}
.p-under-box.has-label .p-under-box__label {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__label {
    margin-bottom: 24px;
  }
}
.p-under-box.has-label .p-under-box__desc {
  margin-top: 0;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .p-under-box.has-label .p-under-box__desc {
    margin-bottom: 36px;
  }
}
.p-under-box__bg01 {
  padding: 50px 30px;
  background: #005BAC;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 {
    padding: 60px 60px 58px;
  }
}
.p-under-box__bg01 .p-under-box {
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .p-under-box__bg01 .p-under-box {
    margin-bottom: 40px;
  }
}
.p-under-box__bg01 .p-under-box__ttl, .p-under-box__bg01 .p-under-box__desc, .p-under-box__bg01 .c-sbtn__txt {
  color: #fff;
}
.p-under-box__bg01 .c-circle-arrow01__arrow {
  background: #fff;
}
.p-under-box__bg01 .c-circle-arrow01__arrow::after {
  border-color: #005BAC;
}
.p-under-box__bg01 .p-under-box__ttl {
  position: relative;
  margin-bottom: 5px;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 .p-under-box__ttl {
    top: -4px;
    margin-bottom: 0;
  }
}
.p-under-box__bg01 .p-under-box__desc {
  margin-top: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 1200px) {
  .p-under-box__bg01 .p-under-box__desc {
    margin-top: 5px;
    margin-bottom: 56px;
  }
}
.p-under-box__block {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__block {
    margin-bottom: 15px;
  }
}
.p-under-box__block:last-child {
  margin-bottom: 0;
}
.p-under-box__block .desc {
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  color: #232536;
  padding-left: 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box__block .desc {
    padding-left: 17px;
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-under-box-style01 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box-style01 {
    align-items: flex-start;
  }
}
.p-under-box-style01 .c-section-stitle .en::before {
  top: 5px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-section-stitle .en::before {
    top: 1px;
  }
}
.p-under-box-style01__reverse {
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__reverse {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__reverse .p-under-box01__info {
    padding-left: 10px;
  }
}
.p-under-box-style01__img {
  width: 100%;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__img {
    width: 47%;
  }
}
@media screen and (min-width: 1025px) {
  .p-under-box-style01__img {
    width: 48.276%;
  }
}
.p-under-box-style01__info {
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__info {
    width: 48%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .p-under-box-style01__info {
    width: 43%;
  }
}
.p-under-box-style01__desc .desc {
  color: #232536;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01__desc .desc {
    font-size: 1.125rem;
  }
}
.p-under-box-style01__desc .desc:last-child {
  margin-bottom: 0;
}
.p-under-box-style01:last-child {
  margin-bottom: 0 !important;
}
.p-under-box-style01 .c-slist-btn {
  justify-content: flex-start;
  margin: 24px 0 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn {
    margin: 56px 0 0;
  }
}
.p-under-box-style01 .c-slist-btn .c-link-btn01 {
  margin: 0 0 10px;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01 {
    margin: 0 0 10px;
  }
}
.p-under-box-style01 .c-slist-btn .c-link-btn01:last-child {
  margin: 0;
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01__link-box {
    padding: 14px 16px 18px;
  }
}
@media screen and (min-width: 768px) {
  .p-under-box-style01 .c-slist-btn .c-link-btn01__link-box::after {
    top: calc(50% - 9px);
  }
}

.p-logistics-system-specification .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-section-stitle01 {
    margin-bottom: 38px;
  }
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-stbl__head {
    width: 22.2%;
    padding: 14px 30px;
  }
}
@media screen and (min-width: 768px) {
  .p-logistics-system-specification .c-stbl__content {
    width: 78.8%;
    padding: 14px 43px;
  }
}

.p-logistics-system-diagram .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-diagram .c-section-stitle01 {
    margin-bottom: 40px;
  }
}
.p-logistics-system-diagram__img {
  text-align: center;
  width: 100%;
  line-height: 0;
}

.p-logistics-system-station .c-section-stitle01 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-station .c-section-stitle01 {
    margin-bottom: 41px;
  }
}
.p-logistics-system-station .desc {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
}
@media screen and (min-width: 768px) {
  .p-logistics-system-station .desc {
    font-size: 1rem;
  }
}

.p-logistics-system__list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__list {
    flex-wrap: nowrap;
  }
}
.p-logistics-system__i {
  width: 100%;
  margin: 0 0 50px;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__i {
    width: 31.035%;
    margin: 0 25px 0 0;
  }
  .p-logistics-system__i:nth-child(3n+3) {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .p-logistics-system__i {
    margin: 0 40px 0 0;
  }
}
.p-logistics-system__i:last-child {
  margin: 0;
}
.p-logistics-system__iimg {
  text-align: center;
  margin: 0 auto 10px;
  line-height: 0;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__iimg {
    margin: 0 auto 28px;
  }
}
.p-logistics-system__idesc {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #232536;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__idesc {
    line-height: 2em;
    font-size: 1rem;
  }
}
.p-logistics-system__ittl {
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 2px;
  color: #005BAC;
  line-height: 1.7em;
}
@media screen and (min-width: 768px) {
  .p-logistics-system__ittl {
    margin-bottom: 13px;
    font-size: 1.25rem;
  }
}

.p-floor-system-product {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px #D1D4D7;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product {
    padding-bottom: 100px;
    margin-bottom: 100px;
  }
}
.p-floor-system-product:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.p-floor-system-product__imgs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__imgs {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__imgs.flex-start {
    align-items: flex-start;
  }
}
.p-floor-system-product__img01 {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto 30px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__img01 {
    width: 48.277%;
    margin: 0;
  }
}
.p-floor-system-product__img02 {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__img02 {
    margin: 0;
    width: calc(51.723% - 40px);
  }
}
.p-floor-system-product__ttl01 {
  color: #232536;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.533em;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl01 {
    margin-bottom: 28px;
    font-size: 1.5rem;
  }
}
.p-floor-system-product__ttl02 {
  position: relative;
  color: #005BAC;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin-bottom: 5px;
  padding-left: 20px;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl02 {
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
  }
}
.p-floor-system-product__ttl02::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #005BAC;
}
@media screen and (min-width: 768px) {
  .p-floor-system-product__ttl02::before {
    width: 10px;
    height: 10px;
    top: 8px;
  }
}
.p-floor-system-product__frame {
  border: solid 1px #D1D4D7;
  padding: 30px 80px;
}
.p-floor-system-product__frame .p-floor-system-product__imgs {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
/* =========================================================================================================
	* foundation   //基準の設定
========================================================================================================= */
/* =========================================================================================================
	* layout _l
========================================================================================================= */
/* =========================================================================================================
  * component _c
========================================================================================================= */