@use "../../global/" as g;

.l-footer {
  letter-spacing: 0.02em;
  &__top {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: g.$gray04;

    @include g.mq(md) {
      padding-top: 112px;
      padding-bottom: 112px;
    }
  }

  &__top-top {
    @include g.mq(lg) {
      display: flex;
    }
  }

  &__top-left {
    @include g.mq(lg) {
      width: calc(320 / 1160 * 100%);
      margin-right: 100px;
    }
  }

  &__logo {
    max-width: 320px;
    width: 100%;
  }

  &__company-name {
    display: flex;
    flex-direction: column;
    margin-top: 27px;
  }

  &__company-name-ja {
    color: g.$white;
    font-size: g.rem(20);
  }

  &__company-name-en {
    text-transform: uppercase;
    color: g.$gray03;
    font-weight: 500;
    margin-top: -3px;
  }

  &__company-address {
    display: flex;
    flex-direction: column;
    color: g.$gray05;
    margin-top: 6px;
  }

  &__top-site-map {
    margin-top: 20px;

    @include g.mq(md) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }

    @include g.mq(lg) {
      gap: 65px;
    }

    @include g.mq(xl) {
      gap: 65px;
    }
  }

  &__top-site-map-center,
  &__top-site-map-right {
    margin-top: 6px;

    @include g.mq(md) {
      margin-top: 0;
    }
  }

  &__top-site-map-item {
    color: g.$white;
    letter-spacing: 0.02em;

    @include g.mq(md) {
      font-size: g.rem(16);
    }

    &.u-bk-service {
      background: url("../../images/common/img_footer-service-bk.webp")
        no-repeat center;
      background-size: cover;
      padding: 10px 0 10px 12px;
      font-size: g.rem(14);
      font-weight: 700;

      .c-circle-arrow01__arrow {
        margin-left: 10px;
      }
    }

    + .l-footer__top-site-map-item.u-bk-service {
      @include g.mq(md) {
        margin-top: 30px !important;
      }
    }

    + .l-footer__top-site-map-item {
      margin-top: 20px;

      @include g.mq(md) {
        margin-top: 20px;
      }
    }

    a {
      display: flex;
      align-items: center;
    }

    .c-circle-arrow01 {
      position: static;

      &::after {
        position: static;
      }
    }

    .c-circle-arrow01__arrow {
      background-color: transparent;

      &::after {
        top: calc(50% + 2.5px);
      }
    }
  }

  &__top-site-map-under-lists {
    color: g.$gray05;
    margin-top: 4px;
    letter-spacing: 0.02em;
  }

  &__top-site-map-under02-lists {
    color: g.$gray05;
    margin-top: 4px;
    letter-spacing: 0.02em;
    padding-left: 15px;
  }

  &__top-site-map-under-item {
    position: relative;

    a {
      &::before {
        content: "－";
        display: inline-block;
      }
    }

    + .l-footer__top-site-map-under-item {
      margin-top: 8px;

      @include g.mq(md) {
        margin-top: 0;
      }
    }
  }

  &__banner {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    flex-direction: column;

    @include g.mq(md) {
      margin-top: 160px;
      align-items: center;
      flex-direction: row;
      gap: 40px;
    }
  }

  &__banner-item {
    @include g.mq(md) {
      max-width: 345px;
      width: 100%;
    }
  }

  &__bottom {
    background-color: g.$gray03;
    padding: 14px 0;
  }

  &__bottom-inner {
    letter-spacing: 0.02em;

    @include g.mq(md) {
      display: flex;
      justify-content: space-between;
    }
  }
}
