@use "../../global/" as g;
@use "sass:map";

.l-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: map.get(g.$layer, header);

  &__inner {
    background-color: g.$gray02;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    height: 75px;

    @include g.mq(md) {
      height: 90px;
      padding-left: 65px;
      padding-right: 65px;
    }

    @include g.mq(xl) {
      height: 110px;
      padding-left: calc(100vw / 1600 * 60);
      padding-right: 0;
    }

    @include g.mq(xxl) {
      padding-left: 65px;
    }
  }

  //ロゴ周り

  &__logo {
    max-width: 207px;
    width: 100%;

    @include g.mq(md) {
      max-width: 268px;
    }
    // width: 100%;
    a {
      display: flex;
      align-items: center;
    }
  }

  //headrメニュー
  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include g.mq(xl) {
      align-items: stretch;
      height: 100%;
      width: 100%;
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.2);
      content: "";
      height: 100vh;
      left: 0px;
      opacity: 0;
      position: absolute;
      top: 100%;
      visibility: hidden;
      width: 100%;
      transition: 0.4s ease 0s;
    }

    &.is-drop-open {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__menu-list {
    display: none;

    @include g.mq(xl) {
      display: flex;
      height: 100%;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      font-size: g.rem(16);
      @include g.clamp-fz(14, 16, 1600);
      letter-spacing: 0.02em;
      margin-right: 45px;
      margin-right: clamp(20px, calc((100vw / 1600) * 45), 45px);
    }
  }

  &__menu-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__menu-item {
    height: 100%;
    // cursor: pointer;

    @include g.mq(md) {
      cursor: pointer;
    }

    > a {
      &:hover {
        color: g.$blue02;
        opacity: 1;
      }
    }
    + .l-header__menu-item {
      margin-left: clamp(20px, calc((100vw / 1600) * 40), 40px);

      @include g.mq(xl) {
        margin-left: clamp(20px, calc((100vw / 1400) * 20), 20px);
      }

      @include g.mq(xxl) {
        margin-left: 40px;
      }
    }

    .c-icon-blank {
      position: relative;
      padding-right: 24px;
      &::after {
        margin-left: 4px;
        position: absolute;
        top: calc(50% - 7.5px);
      }
    }

    .c-under-nav {
      position: relative;
      padding-right: 20px;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 1px;
        background-color: g.$blue02;
        position: absolute;
        right: 0;
        top: calc(50% - 0.5px);
      }

      &::after {
        transition: 0.4s ease-in-out;
        transform: rotate(90deg);
      }
    }

    &.is-drop-open {
      .c-under-nav {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__menu-contact-box {
    display: none;

    @include g.mq(xl) {
      white-space: nowrap;
      background-color: g.$blue02;

      height: 100%;
      color: g.$white;
      display: flex;
      align-items: center;
      font-size: g.rem(18);
      @include g.clamp-fz(14, 18, 1600);
      font-weight: 700;
      letter-spacing: 0.02em;
      position: relative;
      padding-right: clamp(20px, calc(100vw / 1600 * 44), 44px);
      padding-left: clamp(20px, calc(100vw / 1600 * 44), 44px);

      &::after {
        content: "";
        top: calc(50% - 10.5px);
        background: url("../../images/common/icon_contact.svg") no-repeat center;
        background-size: cover;
        width: 24px;
        height: 21px;
        margin-left: 12px;
        margin-top: 4px;
      }
    }
  }

  &__hambergur {
    display: block !important;

    @include g.mq(xl) {
      display: none !important;
    }
  }

  // pcドロップダウン
  &__menu-drop-down {
    background-color: g.$white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 0;
  }

  &__menu-drop-down-inner {
    max-width: 1240px;
    padding: 56px 0;
    padding-left: 40px;
    padding-right: 40px;

    margin: 0 auto;
    display: flex;
  }

  &__menu-drop-down-header {
    width: calc((300 / 1160 * 100%) - 1px);
    border-right: 1px solid g.$border;
  }

  &__menu-drop-down-title {
    font-size: g.rem(20);
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  &__menu-drop-down-main {
    width: calc(860 / 1160 * 100%);
    padding-left: 40px;
  }

  &__menu-drop-down-lists {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px calc(24 / 860 * 100%);
  }

  &__menu-drop-down-item {
    position: relative;

    .c-circle-arrow01 {
      left: 0;
      right: auto;
      top: calc(50% - 13.5px);
    }

    .c-circle-arrow01__arrow {
      background-color: g.$white;
      border: 1px solid g.$blue02;
      width: 27px;
      height: 27px;
      transition: 0.3s ease-in-out;

      &:after {
        border-bottom: 1px solid g.$blue02;
        border-right: 1px solid g.$blue02;
        top: calc(50% - 2.5px);
        // left: calc(50% - 7px);
        left: calc(50% - 8.5px);
        width: 12px;
        height: 5px;
        transition: 0.3s ease-in-out;
      }
    }

    &:hover {
      a {
        opacity: 1;
      }
      .c-circle-arrow01__arrow {
        background-color: g.$blue02;
        border: 1px solid g.$blue02;

        &::after {
          border-bottom: 1px solid g.$white;
          border-right: 1px solid g.$white;
        }
      }
    }
  }

  &__menu-drop-down-item a {
    display: flex;
  }

  &__menu-drop-down-item-box {
    font-size: g.rem(16);
    font-weight: 700;
    letter-spacing: 0.02em;
    position: relative;
    margin-left: 40px;
    white-space: nowrap;

    // .c-circle-arrow01 {
    //   left: 0;
    //   right: auto;
    //   top: calc(50% - 13.5px);
    // }

    // .c-circle-arrow01__arrow {
    //   background-color: g.$white;
    //   border: 1px solid g.$blue02;
    //   width: 27px;
    //   height: 27px;
    //   transition: 0.3s ease-in-out;

    //   &:after {
    //     border-bottom: 1px solid g.$blue02;
    //     border-right: 1px solid g.$blue02;
    //     top: calc(50% - 2.5px);
    //     // left: calc(50% - 7px);
    //     left: calc(50% - 8.5px);
    //     width: 12px;
    //     height: 5px;
    //     transition: 0.3s ease-in-out;
    //   }
    // }
  }

  // sp-nav
  &__sp-nav {
    visibility: hidden;
    transition: 0.4s ease-in-out;
    height: 0;
    left: 0px;
    overflow-y: auto;
    position: fixed;
    top: 75px;
    bottom: 0;
    width: 100%;
    background-color: g.$white;

    .is-nav-open & {
      opacity: 1;
      visibility: visible;
      height: calc(100% - 75px);
    }
  }

  &__sp-nav-content {
    height: auto;
  }

  &__sp-nav-menu {
    padding: 20px 20px;
  }

  &__sp-nav-lists {
    border-bottom: 1px solid g.$border;
  }

  &__sp-nav-item {
    font-size: g.rem(16);
    font-weight: 700;
    letter-spacing: 0.02;

    .l-header__sp-nav-item-box {
      display: block;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .c-icon-blank {
      position: relative;
      &::after {
        position: absolute;
        right: 10px;
        top: calc(50% - 5px);
      }
    }

    + .l-header__sp-nav-item {
      border-top: 1px solid g.$border;
      .l-header__sp-nav-item-box {
        padding-top: 10px;
      }
    }

    .c-under-nav {
      position: relative;
      padding-right: 20px;
      display: block;
      height: 100%;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 1px;
        background-color: g.$blue02;
        position: absolute;
        right: 10px;
        top: calc(50% - 0.5px);
      }

      &::after {
        transition: 0.4s ease-in-out;
        transform: rotate(90deg);
      }
    }

    &.is-drop-open {
      .l-header__sp-nav-item-box {
        background-color: g.$gray03;
      }
      .c-under-nav {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__sp-nav-dropdown {
    display: none;
  }

  &__sp-nav-dropdown-inner {
    padding: 12px 20px 20px;
  }

  &__sp-nav-dropdown-item {
    font-size: g.rem(14);
    font-weight: 500;

    + .l-header__sp-nav-dropdown-item {
      margin-top: 16px;
    }
  }

  &__sp-contact {
    margin-top: 20px;
    padding-bottom: 40px;
  }

  &__sp-contact-box {
    white-space: nowrap;
    background-color: g.$blue02;
    height: 100%;
    color: g.$white;
    display: flex;
    align-items: center;
    font-size: g.rem(16);
    @include g.clamp-fz(14, 18, 1600);
    font-weight: 700;
    letter-spacing: 0.02em;
    position: relative;
    padding: 20px 10px;

    &::after {
      content: "";
      background: url("../../images/common/icon_contact.svg") no-repeat center;
      background-size: cover;
      width: 24px;
      height: 21px;
      position: absolute;
      right: 10px;
      top: calc(50% - 10.5px);
    }
  }
}
