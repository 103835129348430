@use "../../global/" as g;
.l-logistics-system-specification{
    padding: 40px 0 30px;
    @include g.mq('md'){
        padding: 98px 0 30px;
    }
}
.l-logistics-system-diagram{
    padding: 30px 0;
    @include g.mq('md'){
        padding: 45px 0 0;
    }
}
.l-logistics-system-station{
    padding: 30px 0 60px;
    @include g.mq('md'){
        padding:77px 0 160px;
    }
}