@use "../../global/" as g;
.l-sframe-btns{
    background: #005BAC;
    padding: 35px 0;
    @include g.mq('md'){
        padding: 40px 0 20px;
    }
    &.has-2block{
        @include g.mq('md') {
            padding: 41px 0 29px;
        }
    }
    &.has-1btn{
        @include g.mq('md'){
            padding: 40px 0;
        }
        .c-sframe-btns {
            justify-content: center;
            @include g.mq('md') {
                justify-content: inherit;
            }
        }
    }
}
.l-sframe-btn{
    padding-bottom: 38px;
    border-bottom: solid 1px #fff;
    margin-bottom: 19px;
    @include g.mq('md'){
        padding-bottom: 30px;
        border-bottom: solid 2px #fff;
        margin-bottom: 38px;
    }
    &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
}