@use "../../global/" as g;

.l-under-top {
  position: relative;
  z-index: 0;
  overflow: hidden;
  &__main {
    background-color: g.$white;
    padding: 60px 0 60px;

    @include g.mq(md) {
      padding: 145px 0 150px;
    }
  }

  &__bk-content01 {
    position: absolute;
    // right: -120%;
    // bottom: -50%;
    // right: -92px;
    // bottom: -150px;
    right: -30vw;
    bottom: -8vw;

    @include g.mq(sm) {
      // right: -77%;
      // bottom: -40%;
      // right: -92px;
      // bottom: -150px;
      right: -20vw;
      bottom: -8vw;
    }

    @include g.mq(md) {
      // right: -92px;
      // bottom: -150px;
      right: -6vw;
      bottom: -9vw;
    }

    svg {
      aspect-ratio: 795.216 / 450.264;
      // width: calc(100vw / 1160 * 795);
      width: clamp(400px, calc(100vw / 1160 * 795), 798px);
    }
  }

  &__bk-content02 {
    position: absolute;
    // right: -88%;
    // bottom: -50%;
    right: -23vw;
    bottom: 3vw;

    @include g.mq(sm) {
      // right: -60%;
      // bottom: -40%;
      right: -15vw;
      bottom: 0vw;
    }

    @include g.mq(md) {
      // right: -100px;
      // bottom: -16px;
      right: -6vw;
      bottom: -1vw;
    }

    svg {
      aspect-ratio: 867.587 / 300.969;
      width: clamp(400px, calc(100vw / 1160 * 798), 798px);
    }
  }

  .ball {
    opacity: 0;
    visibility: hidden;
    animation: fadeIn 5s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    80% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  &01{
   
    .l-breadcrumbs, .l-breadcrumbs__lists{
      justify-content: flex-start;
    }
    .l-breadcrumbs__lists{
      @include g.mq('md') {
        padding-bottom: 10px;
      }
    }
  }
  &01__main{
    position: relative;
    background: #F8FAF9;
    overflow: hidden;
    z-index: 0;
    padding: 80px 0;
    border-top: solid 1px #2995E5;
    @include g.mq('md'){
      padding: 121px 0 129px;
    }
  }
  &01__main01{
    padding: 40px 0 65px;
    @include g.mq('md'){
      padding: 35px 0 57px;
    }
    &.style01{
      @include g.mq('md'){
        padding: 35px 0 64px;
      }
    }
    .p-under-top01__img {
      margin-bottom: 30px;
      @include g.mq('md'){
        margin-bottom: 0;
      }
    }
    .p-under-top01__desc{
      margin-bottom: 16px;
      @include g.mq('md'){
        margin-bottom: 32px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &01__bk{
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 122px;
    @include g.mq('md'){
      width: 120px;
      height: 245px;
    }
    @include g.mq('xl'){
      width:171px; 
      height:317.41px;
    }
    svg{
      width: 100%;
      height: 100%;
    }
  }
}

