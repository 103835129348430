
@use "../../global/" as g;

.l-scorporate{
    position: relative;
    overflow: hidden;
    padding: 60px 0 80px;
    z-index: 1;
    @include g.mq('md'){
        padding: 84px 0;
    }
    &::before{
        position: absolute;
        content: '';
        left: -100vw;
        top: 0;
        width: 300vw;
        height: 100%;
        background: #fff;
        z-index: -1; 
    }
    .p-scorporate__title{
        font-size: g.rem(22);
        font-weight: 500;
        color: #232536;
        line-height: 1.5em;
        margin-bottom: 30px;
        letter-spacing: 0.02em;
        @include g.mq('md'){
            margin-bottom: 39px;
            font-size: g.rem(24);
        }
    }
    &01{
        @include g.mq('md'){
            padding: 53px 0 81px;
        }
    }
}
