@use "../../global/" as g;

.l-contact {
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-color: g.$blue02;
  padding-top: 50px;
  padding-bottom: 60px;

  @include g.mq(md) {
    padding-top: 130px;
    padding-bottom: 113px;
  }

  &__inner {
    position: relative;
    z-index: 3;
  }

  //   bk パス
  &__bk-content01 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: 2.5vw;
    right: -22vw;
    transform: rotate(-17deg);

    svg {
      // width: 100vw;
      aspect-ratio: 735 / 318;
      width: clamp(400px, calc(100vw / 1600 * 735), 735px);
    }

    // @include g.mq(lg) {
    //   top: -8%;
    //   left: -28%;
    // }
  }

  &__bk-content02 {
    position: absolute;
    z-index: 1;
    // top: -9vw;
    bottom: 1.5vw;
    right: -19vw;
    transform: rotate(-20deg);
    svg {
      // width: 100vw;
      aspect-ratio: 649 / 453;
      // width: calc(100vw / 1600 * 800);
      width: clamp(400px, calc(100vw / 1600 * 649), 649px);
    }
  }
}
